<template>
  <div>
    <!-- {{ data }} -->

    <div class="mt-4 mb-2 text-center">
      <!-- <h1>
        แพ็กเกจ/ราคา <br />
        เลือกเซ็ตแพ็กเกจ ที่เหมาะกับคุณ
      </h1> -->
      <h1>SOCIO Wi-Fi</h1>
      <h2>
        มาครบ จบในเซ็ต <br />
        เซ็ตเครื่อง SOCIO Wi-Fi พร้อมระบบ
      </h2>
    </div>
    <div>
      <b-row>
        <b-col
          md="12"
          lg="12"
          xl="6"
          v-for="(product, index) in data"
          :key="index"
        >
          <b-card>
            <b-row>
              <b-col md="12" xl="6" lg="6">
                <div align="center">
                  <b-img :src="product.image" fluid style="width: 250px" />
                </div>
              </b-col>
              <b-col md="12" xl="6" lg="6">
                <div>
                  <div v-html="product.detail"></div>

                  <h2 class="card-text mb-0">
                    ราคา
                    {{
                      new Intl.NumberFormat("en-IN").format(
                        product.selling_price
                      )
                    }}บาท
                  </h2>

                  <br />
                  <h4 class="card-text text-danger mb-0">
                    (ราคาปกติ
                    {{
                      new Intl.NumberFormat("en-IN").format(product.price)
                    }}บาท)
                  </h4>

                  <p class="card-text mb-0">
                    รวมภาษีมูลค่าเพิ่มแล้ว
                  </p>

                  <div @click="payment_options(product.id)">
                    <b-button variant="warning">
                      <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                      <span>ซื้อ</span>
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCardBody,
  BCardText,
  BCard,
  BImg,
  BLink,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import liff from "@line/liff";
import api from "@/api";
export default {
  components: {
    liff,
    BCardBody,
    BCardText,
    BCard,
    BImg,
    BLink,
    BRow,
    BCol,
    BButton,
  },

  data() {
    return {
      data_profile: "",
      lineID: "",
      name: "",
      email: "",
      picture: "",
      currentUrl: "",
      data: [],
    };
  },
  created() {
   
    if (
      localStorage.getItem("vuexy-skin") !== "light" ||
      !localStorage.getItem("vuexy-skin")
    ) {
      console.log("light :>> ");
      localStorage.setItem("vuexy-skin", "light");
      window.location.reload();
    }

    this.currentUrl = window.location.origin;
    liff
      .init({
        liffId: "1657484988-oMMd0892",
      })
      .then(() => {
        console.log("then :>> ");
        console.log("liff.isLoggedIn() :>> ", liff.isLoggedIn());
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          liff.getFriendship().then((data) => {
            if (data.friendFlag) {
              console.log("data.friendFlag :>> ", data.friendFlag);
              console.log("เปงงงงงกัง! เพื่อนกันยุแว้วว");
              this.data_profile = liff.getDecodedIDToken();
              console.log("this.data_profile :>> ", this.data_profile);
              this.lineID = this.data_profile.sub;
              this.name = this.data_profile.name;
              this.email = this.data_profile.email;
              this.picture = this.data_profile.picture;
              // alert(JSON.stringify(data));
              // alert(JSON.stringify( this.data_profile));
              localStorage.setItem(
                "data_profile",
                JSON.stringify(this.data_profile)
              );
            } else {
              console.log("data.friendFlag :>> ", data.friendFlag);
              // alert(JSON.stringify(data));
              var url =
                "https://access.line.me/oauth2/v2.1/authorize?client_id=1657484988&response_type=code&redirect_uri=https://manager.socio.co.th/liff/index&logintype=web&scope=openid%20profile%20email&state=XXX&nonce=09876xyz&auth0&bot_prompt=aggressive&prompt=consent";
              console.log("url :>> ", url);
              window.location.href = url;
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      api
        .get("products", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.data = response.data.products;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    payment_options(id) {
      this.$router.push("/liff/sales/checkout?id=" + id);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
</style>
